@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "../assets/scss/wysiwyg.scss";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root > div {
	min-height: 100vh;
}

.htmlInterpreter {
  h1 {
    font-weight: bold;
	font-size: 26px;
	text-decoration: underline;
  }
  h2 {
    font-weight: bold;
	font-size: 22px;
  }
  h3 {
    font-weight: bold;
	font-size: 18px;
  }
}


  h1 {
    @apply text-3xl font-extrabold dark:text-white
  }
  h2 {
    @apply text-2xl font-extrabold dark:text-white
  }	
  h3 {
    @apply font-extrabold dark:text-white
  }