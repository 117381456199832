.rdw-editor-toolbar {
    margin: 0;
    padding: 0;
}

.editor-toolbar {
    @apply bg-base-300 text-black p-3 border-none flex justify-center
    
}

.rdw-option-wrapper  {
    min-height: 30px;
    min-width: 30px;
}